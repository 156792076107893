// Variables

// Color system

// gray-color-variables
$white:    #fff !default;
$gray-100: #F1F3F6 !default;
$gray-200: #EDEEF1 !default;
$gray-300: #DBE0EB !default;
$gray-400: #C3CBD1 !default;
$gray-500: #A2A9BB !default;
$gray-600: #737988 !default;
$gray-700: #474C57 !default;
$gray-800: #2B303A !default;
$gray-900: #191B24 !default;
$black:    #000000 !default;

// color-variables
$blue:      #30BCED !default;
$dark-blue: #1e2229 !default;
$red:       #E8346A !default;
$orange:    #FF7A00 !default;
$green:     #34DC77 !default;
$teal:      #6EEB83 !default;
$cyan:      #0dcaf0 !default;

// theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $white !default;
$dark:          $gray-900 !default;
$text-color:    $white !default;
$btn-secondary: $dark-blue !default;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;


$min-contrast-ratio: 3 !default;

// Options
$enable-validation-icons:     false !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .5,
  2: $spacer,
  3: $spacer * 1.25,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 4,
) !default;

// Body
$body-bg:                   opaque($black, rgba(43, 48, 58, 0.7)) !default;
$body-color:                $gray-800 !default;

// Links
$link-color:                              $primary !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;

// Grid & Containers

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 632px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

$grid-gutter-width:           1rem !default;
$container-padding-x:         $grid-gutter-width !default;

// Components

$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .75rem !default;
$border-radius-pill:          50rem !default;

$box-shadow:                  0 .25rem .875rem rgba($black, .45) !default;

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

$transition-collapse:         height .10s ease !default;

// Typography
$font-family-sans-serif:      'Nunito', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-weight-bold:            700 !default;
$font-size-base:              1rem !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2 !default;
$h2-font-size:                $font-size-base * 1.5 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base !default;
$h5-font-size:                $font-size-base !default;
$h6-font-size:                $font-size-base * 0.75 !default;

$headings-font-weight:        700 !default;

$small-font-size:             .875em !default;

// Buttons + Forms

// scss-docs-start input-btn-variables
$input-btn-padding-y-lg:      .6875rem !default;
$input-btn-padding-x-lg:      .75rem !default;
$input-btn-font-size-lg:      $font-size-base !default;


$input-btn-border-width:      map-get($border-widths, 2) !default;

//$input-font-size-lg:                    $font-size-base !default;
$input-bg:                              $gray-200 !default;
$input-border-color:                    $gray-200 !default;

// Buttons

// btn-variables
$btn-padding-x-lg:            1.25rem !default;
$btn-disabled-opacity:        .5 !default;

$btn-font-weight:             $font-weight-bold !default;

$btn-border-radius:           $border-radius-pill !default;
$btn-border-radius-sm:        $border-radius-pill !default;
$btn-border-radius-lg:        $border-radius-pill !default;

// Forms

// form-label-variables
$form-label-font-weight:            700 !default;

// form-input-variables

// form-check-variables
//$form-check-margin-bottom:                1rem !default;
$form-check-input-width:                  1.5em !default;
$form-check-label-cursor:                 pointer !default;
$form-check-input-border:                 none !default;

// form-select-variables
$form-select-padding-x:             $input-btn-padding-x-lg !default;
$form-select-indicator-padding:     $form-select-padding-x * 4 !default;
$form-select-font-weight:           700 !default;
$form-select-color:                 $primary !default;
$form-select-bg-position:           right 1rem center !default;
$form-select-bg-size:               16px 16px !default;

// form-range-variables
$form-range-track-bg:               $gray-100 !default;

$form-range-thumb-width:            2rem !default;
$form-range-thumb-bg:               $component-active-bg !default;
$form-range-thumb-active-bg:        $component-active-bg !default;

// Navs
$nav-link-color:                    $body-color !default;
$nav-link-hover-color:              $link-color !default;

$nav-tabs-border-width:             map-get($border-widths, 2) !default;
$nav-tabs-link-active-color:        $link-color !default;

// Navbar
$navbar-toggler-border-radius:      $border-radius !default;
$navbar-toggler-focus-width:        0 !default;

// Survey List
$bg-list: $white !default;

// Cards
$card-spacer-y:                     map-get($spacers, 2) !default;
$card-spacer-x:                     map-get($spacers, 2) !default;
$card-border-color:                 $white !default;
$card-border-radius:                $border-radius-lg !default;
$card-box-shadow:                   0 .25rem 1.5rem rgba($black, .32) !default;
$card-cap-padding-y:                $card-spacer-y !default;
$card-cap-bg:                       transparent !default;

// Modals

$modal-header-border-width:         0 !default;
$modal-footer-border-width:         0 !default;

// Alerts
$alert-padding-y:                   map-get($spacers, 3) !default;
$alert-padding-x:                   map-get($spacers, 3) !default;
$alert-border-radius:               $border-radius !default;
$alert-border-width:                0 !default;
$alert-bg-scale:                    -84% !default;
$alert-color-scale:                 0% !default;

// Progress bars
$progress-height:                   0.25rem !default;

// Carousel

// scss-docs-start carousel-variables
$carousel-indicator-width:           8px !default;
$carousel-indicator-height:          8px !default;
$carousel-indicator-hit-area-height: 24px !default;
$carousel-indicator-spacer:          map-get($spacers, 1) !default;
$carousel-indicator-opacity:         1 !default;
$carousel-indicator-active-bg:       $body-bg !default;
$carousel-indicator-active-opacity:  1 !default;

:root {
  /* Color system */
  --white:    #{$white};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --gray-900: #{$gray-900};
  --black:    #{$black};

  /* Colors */
  --blue:      #{$blue};
  --dark-blue: #{$dark-blue};
  --red:       #{$red};
  --orange:    #{$orange};
  --green:     #{$green};
  --teal:      #{$teal};
  --cyan:      #{$cyan};

  /* Theme colors */
  --primary:    #{$primary};
  --secondary:  #{$secondary};
  --success:    #{$success};
  --info:       #{$info};
  --warning:    #{$warning};
  --danger:     #{$danger};
  --light:      #{$light};
  --dark:       #{$dark};
  --text-color: #{$text-color};
  --btn-secondary: #{$btn-secondary};
  --bg-list:    #{$bg-list};
  --bg-footer: #{$gray-900};

  /* Options */
  --min-contrast-ratio: #{$min-contrast-ratio};
  --enable-validation-icons: #{$enable-validation-icons};

  /* Spacing */
  --spacer: #{$spacer};
  --spacer-0: 0;
  --spacer-1: #{map-get($spacers, 1)};
  --spacer-2: #{map-get($spacers, 2)};
  --spacer-3: #{map-get($spacers, 3)};
  --spacer-4: #{map-get($spacers, 4)};
  --spacer-5: #{map-get($spacers, 5)};
  --spacer-6: #{map-get($spacers, 6)};
  --spacer-7: #{map-get($spacers, 7)};

  /* Body */
  --body-bg: #{$body-bg};
  --body-color: #{$body-color};

  /* Links */
  --link-color: #{$link-color};
  --link-shade-percentage: #{$link-shade-percentage};

  /* Grid & Containers */
  --container-max-width-sm: #{map-get($container-max-widths, sm)};
  --container-max-width-md: #{map-get($container-max-widths, md)};
  --container-max-width-lg: #{map-get($container-max-widths, lg)};
  --container-max-width-xl: #{map-get($container-max-widths, xl)};
  --container-max-width-xxl: #{map-get($container-max-widths, xxl)};
  --grid-gutter-width: #{$grid-gutter-width};
  --container-padding-x: #{$container-padding-x};

  /* Components */
  --border-width: #{$border-width};
  --border-width-1: #{map-get($border-widths, 1)};
  --border-width-2: #{map-get($border-widths, 2)};
  --border-width-3: #{map-get($border-widths, 3)};
  --border-width-4: #{map-get($border-widths, 4)};
  --border-width-5: #{map-get($border-widths, 5)};

  --border-radius: #{$border-radius};
  --border-radius-sm: #{$border-radius-sm};
  --border-radius-lg: #{$border-radius-lg};
  --border-radius-pill: #{$border-radius-pill};

  --box-shadow: #{$box-shadow};
  --component-active-color: #{$component-active-color};
  --component-active-bg: #{$component-active-bg};

  --transition-collapse: #{$transition-collapse};

  /* Typography */
  --font-family-sans-serif: #{$font-family-sans-serif};
  --font-weight-bold: #{$font-weight-bold};
  --font-size-base: #{$font-size-base};
  --line-height-base: #{$line-height-base};

  --h1-font-size: #{$h1-font-size};
  --h2-font-size: #{$h2-font-size};
  --h3-font-size: #{$h3-font-size};
  --h4-font-size: #{$h4-font-size};
  --h5-font-size: #{$h5-font-size};
  --h6-font-size: #{$h6-font-size};

  --headings-font-weight: #{$headings-font-weight};
  --small-font-size: #{$small-font-size};

  /* Buttons + Forms */
  --input-btn-padding-y-lg: #{$input-btn-padding-y-lg};
  --input-btn-padding-x-lg: #{$input-btn-padding-x-lg};
  --input-btn-font-size-lg: #{$input-btn-font-size-lg};
  --input-btn-border-width: #{$input-btn-border-width};
  --input-bg: #{$input-bg};
  --input-border-color: #{$input-border-color};

  /* Buttons */
  --btn-padding-x-lg: #{$btn-padding-x-lg};
  --btn-disabled-opacity: #{$btn-disabled-opacity};
  --btn-font-weight: #{$btn-font-weight};
  --btn-border-radius: #{$btn-border-radius};
  --btn-border-radius-sm: #{$btn-border-radius-sm};
  --btn-border-radius-lg: #{$btn-border-radius-lg};

  /* Forms */
  --form-label-font-weight: #{$form-label-font-weight};
  --form-check-input-width: #{$form-check-input-width};
  --form-check-label-cursor: #{$form-check-label-cursor};
  --form-check-input-border: #{$form-check-input-border};
  --form-select-padding-x: #{$form-select-padding-x};
  --form-select-indicator-padding: #{$form-select-indicator-padding};
  --form-select-font-weight: #{$form-select-font-weight};
  --form-select-color: #{$form-select-color};
  --form-select-bg-position: #{$form-select-bg-position};
  --form-select-bg-size: #{$form-select-bg-size};
  --form-range-track-bg: #{$form-range-track-bg};
  --form-range-thumb-width: #{$form-range-thumb-width};
  --form-range-thumb-bg: #{$form-range-thumb-bg};
  --form-range-thumb-active-bg: #{$form-range-thumb-active-bg};

  /* Navs */
  --nav-link-color: #{$nav-link-color};
  --nav-link-hover-color: #{$nav-link-hover-color};
  --nav-tabs-border-width: #{$nav-tabs-border-width};
  --nav-tabs-link-active-color: #{$nav-tabs-link-active-color};

  /* Navbar */
  --navbar-toggler-border-radius: #{$navbar-toggler-border-radius};
  --navbar-toggler-focus-width: #{$navbar-toggler-focus-width};

  /* Cards */
  --card-spacer-y: #{$card-spacer-y};
  --card-spacer-x: #{$card-spacer-x};
  --card-border-color: #{$card-border-color};
  --card-border-radius: #{$card-border-radius};
  --card-box-shadow: #{$card-box-shadow};
  --card-cap-padding-y: #{$card-cap-padding-y};
  --card-cap-bg: #{$card-cap-bg};

  /* Modals */
  --modal-header-border-width: #{$modal-header-border-width};
  --modal-footer-border-width: #{$modal-footer-border-width};

  /* Alerts */
  --alert-padding-y: #{$alert-padding-y};
  --alert-padding-x: #{$alert-padding-x};
  --alert-border-radius: #{$alert-border-radius};
  --alert-border-width: #{$alert-border-width};
  --alert-bg-scale: #{$alert-bg-scale};
  --alert-color-scale: #{$alert-color-scale};

  /* Progress bars */
  --progress-height: #{$progress-height};

  /* Carousel */
  --carousel-indicator-width: #{$carousel-indicator-width};
  --carousel-indicator-height: #{$carousel-indicator-height};
  --carousel-indicator-hit-area-height: #{$carousel-indicator-hit-area-height};
  --carousel-indicator-spacer: #{$carousel-indicator-spacer};
  --carousel-indicator-opacity: #{$carousel-indicator-opacity};
  --carousel-indicator-active-bg: #{$carousel-indicator-active-bg};
  --carousel-indicator-active-opacity: #{$carousel-indicator-active-opacity};
}
