.carousel-indicators {
bottom: -55px;

  [data-bs-target] {
    border: map-get($border-widths, 2) solid $white;
    border-radius: $border-radius-pill;
  }

  .active {
    background: $primary;
    border-color: $primary;
  }
}

.carousel-indicators,
.carousel-control {
  &::before,
  &:hover,
  &:focus {
    opacity: 0.5;
    color: none !important;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='000000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='000000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
