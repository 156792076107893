%slime {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  z-index: -1000;
}

.slime1 {
  @extend %slime;
  width: 773px;
  height: 737px;
  background-image: url('/assets/images/Slime1.svg');
}

.slime2 {
  @extend %slime;
  width: 679px;
  height: 677px;
  background-image: url('/assets/images/Slime2.svg');
}

.slime3 {
  @extend %slime;
  width: 710px;
  height: 727px;
  background-image: url('/assets/images/Slime3.svg');
}

.slime4 {
  @extend %slime;
  width: 1070px;
  height: 1120px;
  background-image: url('/assets/images/Slime4.svg');
}

.slime-shade {
  @extend %slime;
  width: 970px;
  height: 821px;
  background-image: url('/assets/images/SlimeShade.svg');
}
