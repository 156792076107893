$table-outline: 1px solid $white;

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
  background: $white;
  font-size: 12px;

  th, td {
    padding: 0px 8px 0px 0px;
  }

  // remove double border
  tr th, tr td {
    border-right: none;
    border-top: none;
    padding: 0px 8px;
  }

  thead tr {
    height: 38px;
  }

  tbody {
    tr {
      height: 48px;

      &:nth-child(2n-1) td {
        background-color: $gray-100;
      }

      td:first-child { 
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      td:last-child { 
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
