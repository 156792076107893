@import './variables';

.esprio-slider .ngx-slider {
  margin-top: 5rem;

  &.with-legend {
    margin-bottom: 4.5rem;
  }

  .ngx-slider-full-bar {
    .ngx-slider-bar {
      width: calc(100% - 16px);
      left: 8px;
    }
  }

  .ngx-slider-bar {
    height: .5rem;
    border-radius: $border-radius-pill;
    border: $border-width solid $gray-400;
    background-color: $gray-100;
  }

  .ngx-slider-pointer {
    background-color: $primary;

    &:after, &:hover:after {
      background-color: $primary;
    }

    &.ngx-slider-active {
      &:after {
        background-color: $primary;
      }
    }
  }

  .ngx-slider-bubble {
    width: 2rem;
    text-align: center;
    bottom: 40px;
    font-size: 1.375rem;
    color: $white;
    font-weight: $font-weight-bold;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      left: -4px;
      top: -4px;
      width: 40px;
      height: 40px;
      border-radius: 100% 100% 0% 100%/100% 100% 0% 100%;
      transform: rotate(45deg);
      background: tint-color($primary, 30%);
      z-index: -1;
    }
  }

  .ngx-slider-tick {
    background: transparent;
  }

  .ngx-slider-ticks-values-under {
    .ngx-slider-tick-value {
      width: 2rem;
      color: $secondary;
      bottom: -3rem;
    }
  }

  .ngx-slider-tick-legend {
    color: $gray-600;
    font-size: $small-font-size;
    font-weight: 600;
    white-space: nowrap;
    top: 3.5rem
  }

  .ngx-slider-ticks {
    .ngx-slider-tick:first-child {
      .ngx-slider-tick-legend {
        transform: translate(-4px, 0);
        max-width: unset;
      }
    }

    .ngx-slider-tick:last-child {
      .ngx-slider-tick-legend {
        transform: translate(calc(-100% + 8px), 0);
        max-width: unset;
      }
    }
  }
}
