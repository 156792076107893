.alert {
  font-weight: 700;
  display: flex;
  align-items: center;
}

@each $state, $value in $theme-colors {
  .alert-#{$state} {
    color: $body-color;
  }
}
