.chart-wrapper {
  div.chart-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding: 10px;
    background-color: rgba($white, 0.4);
    font-size: 14px;
    color: $gray-500;
  }
}
