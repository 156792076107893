.form-control {
  &::placeholder {
    font-style: italic;
  }
}

.form-control:disabled {
  background-color: #bdbdbd;
}

.form-check-label {
  font-weight: $form-label-font-weight;
}

.form-range {
  &::-webkit-slider-runnable-track {
    box-shadow: inset 0 0 0 $border-width $gray-400;
  }
  &::-moz-range-track {
    box-shadow: inset 0 0 0 $border-width $gray-400;
  }
}
