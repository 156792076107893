.default-modal-backdrop {
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(2px);
}

.default-modal-body {
  background-color: $white;
  border-radius: $card-border-radius;
  overflow-y: auto;
  max-width: map-get($container-max-widths, md);
  height: fit-content;
  padding: $card-cap-padding-y;
}

@media (min-width: map-get($container-max-widths, 'md')) {
  .default-modal-body {
    max-width: 60vw;
    max-height: 90vh;
  }
}
