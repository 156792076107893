%survey-dot {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: $border-width solid $white;
}

.bs-datepicker-container {
  padding: 6px;
}

.bs-datepicker {
  border-radius: $border-radius-lg;
  box-shadow: $card-box-shadow;

  bs-datepicker-inline & {
    box-shadow: none;
  }
}

.bs-datepicker-head {
  text-align: center;

  button {
    color: $primary;

    &:hover, &:active {
      background-color: transparent;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active {
      background: transparent;
    }

    &.previous, &.next {
      color: $gray-300;
    }

    &.previous span {
      text-indent: -99999em;
      overflow: hidden;
      background-image: url('/assets/images/Arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      transform: rotate(180deg);;
    }

    &.next span {
      text-indent: -99999em;
      overflow: hidden;
      background-image: url('/assets/images/Arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.current {
      padding: 0;
      font-weight: $font-weight-bold;
    }
  }
}

.bs-datepicker-body {
  border: none;

  table {
    th {
      color: $gray-600;
    }

    td {
      color: $body-color;
      font-weight: $font-weight-bold;

      .survey-success {
        color: $white;
        background: $success !important;
      }

      .survey-danger {
        color: $white;
        background: $danger !important;;
      }

      .survey-warning {
        color: $white;
        background: $warning !important;;
      }

      span.selected,
      &.selected span {
        position: relative;
        background: $primary !important;

        &.survey-success:after {
          @extend %survey-dot;
          background: $success;
        }

        &.survey-danger:after {
          @extend %survey-dot;
          background: $danger;
        }

        &.survey-warning:after {
          @extend %survey-dot;
          background: $warning;
        }
      }

    }
  }
}


.theme-green {
  .bs-datepicker-head {
    background-color: transparent;
  }

  .bs-datepicker-body {
    table {
      td {
        span.selected,
        &.selected span,
        span[class*="select-"]:after,
        &[class*="select-"] span:after {
          background-color: $primary;
        }

        &.week span {
          color: $primary;
        }

        &.active-week span:hover {
          cursor: pointer;
          background-color: tint-color($primary, 80%) ;
          color: $primary;
          opacity: 0.5;
          transition: 0s;
        }
      }
    }
  }
}
